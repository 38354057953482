import React, { useRef } from 'react';
import { Form, InputGroup, useClassNameMapper } from 'react-bootstrap';
import type { FieldValues, NonUndefined, UseFormReturn } from 'react-hook-form';
import type { FieldError } from 'react-hook-form/dist/types/errors';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import ComponentHelper from '../../../helpers/ui/ComponentHelper/ComponentHelper';
import { focusFirstTabbableChildElement } from '../../../helpers/ui/KeyboardNavigationHelper/KeyboardNavigationHelper';
import Icons from '../../../icons';
import { LoadingSize, LoadingSpacing } from '../../../types/enums';
import { IconColor, IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import { LoadingVariant } from '../../common/Loading/enums';
import Loading from '../../common/Loading/Loading';
import type { LoadingVariantTypeAndProps } from '../../common/Loading/types';
import ClearButton from '../ClearButton/ClearButton';
import type { FormFieldVariant } from '../enums';
import FormField from '../FormField/FormField';
import type { FormFieldProps, FormFieldType } from '../types';
import { FieldType } from '../types';
import localStyles from './SearchField.module.pcss';

/**
 * Search Input Field specification to render the search field. NameT is the name of the field and DataT is the
 * definition of bean that holds all the fields for the form.
 */
export interface SearchFieldSpec<NameT extends FieldPath<DataT>, DataT extends FieldValues>
  extends FormFieldType<NameT, DataT, FormFieldVariant.SEARCH> {
  /**
   * The size of the input group
   */
  size?: 'lg' | 'default';
  /**
   * Whether to show the search icon
   */
  useSearchIcon?: boolean;
  /**
   * An optional icon can be provided,
   * which will override the default search icon
   */
  searchIcon?: React.FC;
  /**
   * Whether to show the loading indicator
   */
  isLoading?: boolean;
  /**
   * Whether to show the clear button
   */
  useClearButton?: boolean;
  /**
   * Whether to show the close button
   */
  useCloseButton?: boolean;
  /**
   * callback function invoked when the clear button is pressed
   */
  onClearSearch?: (event: React.TouchEvent | React.MouseEvent) => void;
  /**
   * callback function invoked when the close button is pressed
   */
  onCloseSearch?: (event: React.TouchEvent | React.MouseEvent) => void;
  /**
   * Class name(s) to apply to the InputGroup
   */
  inputGroupClassName?: string;
  /**
   * aria-activedescendant for the input element
   */
  ariaActiveDescendant?: string;
  /**
   * callback function invoked when a keydown event occurs while the input has focus
   */
  onKeyDown?: (event: React.KeyboardEvent, formMethods: UseFormReturn<DataT>) => void | undefined;
  /**
   * Placeholder for search field
   */
  placeholderText?: string;
  /**
   * Callback when input field focused
   */
  onFocus?: (event: React.FocusEvent) => void;
  /**
   * Callback when input field blurred
   */
  onBlur?: (event: React.FocusEvent) => void;
}

/**
 * Renders search input field for the form
 *
 * @author Sravan Reddy, Jonathan Bridges
 */
const SearchField = <NameT extends FieldPath<DataT>, DataT extends FieldValues>({
  fieldSpec,
  formId,
  i18n,
  formMethods,
  onFormSubmit,
  submitOnChange,
  allFields
}: FormFieldProps<NameT, DataT, SearchFieldSpec<NameT, DataT>>): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const { setValue } = formMethods;
  const { formatMessage, hasMessage, loading: textLoading } = i18n;

  const {
    name,
    size = 'default',
    className,
    inputGroupClassName,
    attributes,
    useSearchIcon = true,
    searchIcon: SearchIcon,
    isLoading = false,
    useClearButton = true,
    useCloseButton = false,
    onClearSearch,
    onCloseSearch,
    focus,
    focusHandler,
    onKeyDown,
    ariaActiveDescendant,
    placeholderText,
    onFocus,
    onBlur
  } = fieldSpec;

  const placeHolderKey = `${formId}.${name}.placeholder`;
  const finalPlaceHolder: string =
    placeholderText ?? (hasMessage(placeHolderKey) ? formatMessage(placeHolderKey) : undefined);
  const hasBeenFocused = useRef(false);

  const loadingVariant: LoadingVariantTypeAndProps = {
    type: LoadingVariant.DOT,
    props: {
      size: LoadingSize.SM,
      spacing: LoadingSpacing.SM
    }
  };

  const inputGroupRef = useRef<HTMLDivElement>(null);

  /**
   * Callback function invoked when the clear button is used
   */
  function handleClearSearch(event: React.TouchEvent | React.MouseEvent): void {
    // Always clear value from form state when the clear button is selected
    setValue(name, '' as NonUndefined<DataT[NameT]>);
    if (!!onClearSearch) {
      // if a specific function is provided for the clear button, use it
      onClearSearch(event);
    }
    // set focus back on the input field
    focusFirstTabbableChildElement(inputGroupRef.current);
  }

  /**
   * Renders the search icon which appears visually inside the search field, but technically is before it
   */
  function renderInputGroupPrepend(): React.ReactElement {
    return (
      <InputGroup.Prepend className={cx(`lia-input-group-prepend lia-input-group-prepend-${size}`)}>
        {!SearchIcon && (
          <Icon
            icon={Icons.SearchIcon}
            size={size === 'lg' ? IconSize.PX_20 : IconSize.PX_16}
            color={IconColor.GRAY_700}
          />
        )}
        {SearchIcon && <SearchIcon />}
      </InputGroup.Prepend>
    );
  }

  /**
   * Renders the text input for the search field
   *
   * @param value the value from react-hook-form
   * @param onChange the onChange callback from react-hook-form
   * @param error
   */
  function renderTextInput(
    value: DataT[NameT],
    onChange: (...event: unknown[]) => void,
    error: FieldError
  ): React.ReactElement {
    return (
      <Form.Control
        className={cx(`lia-input-field lia-input-field-${size}`)}
        name={name}
        type="text"
        ref={(element): void => {
          if (focus) {
            ComponentHelper.focusHandler(hasBeenFocused, element, focusHandler);
          }
        }}
        value={value}
        isInvalid={error !== undefined}
        autoComplete="off"
        placeholder={!textLoading && finalPlaceHolder}
        onKeyDown={
          !!onKeyDown
            ? (event: React.KeyboardEvent<Element>) => onKeyDown(event, formMethods)
            : undefined
        }
        aria-label={name}
        aria-activedescendant={ariaActiveDescendant}
        data-testid={`SearchField.${name}`}
        onChange={onChange}
        onFocus={event => onFocus?.(event)}
        onBlur={event => onBlur?.(event)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
      />
    );
  }

  /**
   * Renders the items that appear visually inside the search field, but are technically after it
   * Optionally, a loading indicator and a close button for clearing the search field
   *
   * @param value the value from react-hook-form
   */
  function renderInputGroupAppend(value: DataT[NameT]): React.ReactElement {
    return (
      <InputGroup.Append className={cx(`lia-input-group-append lia-input-group-append-${size}`)}>
        {isLoading && <Loading variant={loadingVariant} />}
        {((!!value && useClearButton) || useCloseButton) && (
          <div className={cx('lia-btn-wrapper')}>
            <ClearButton
              onClear={event => (!!value ? handleClearSearch(event) : onCloseSearch(event))}
            />
          </div>
        )}
      </InputGroup.Append>
    );
  }

  return (
    <FormField
      fieldSpec={fieldSpec}
      fieldType={FieldType.CONTROLLED}
      formId={formId}
      i18n={i18n}
      formMethods={formMethods}
      onFormSubmit={onFormSubmit}
      submitOnChange={submitOnChange}
      allFormFields={allFields}
      className={cx(className)}
    >
      {({
        render: {
          field: { value, onChange },
          fieldState: { error }
        }
      }) => {
        return (
          <InputGroup
            className={cx(`lia-input-group lia-input-group-${size}`, inputGroupClassName)}
            size={size !== 'default' ? size : undefined} // Bootstrap uses 'lg' and 'sm', and no prop for default sizing
            ref={inputGroupRef}
            role="region"
            aria-live="polite"
          >
            {useSearchIcon && renderInputGroupPrepend()}
            {renderTextInput(value, onChange, error)}
            {(isLoading || (!!value && useClearButton) || useCloseButton) &&
              renderInputGroupAppend(value)}
          </InputGroup>
        );
      }}
    </FormField>
  );
};

export default SearchField;
